import { useState, memo } from "react";
import "../completed-services/CompletedServices.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInfoOrder } from "../../../redux/slices/order";
// import { selectIsAuth } from '../../../redux/slices/auth';
import arrowImage from "../../../assets/arrow-small.png";
import { months } from "../../../data";
import ChangeOrder from "./ChangeOrder";
import CancelOrder from "./CancelOrder";

const MyOrders = memo(() => {
  const [modal, setModal] = useState(false);
  const [dataChange, setDataChange] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelId, setCancelId] = useState(false);

  const handleOpenModal = (value, data = null) => {
    setModal(value);
    setDataChange(data);
  };

  const handleCancelOrder = value => {
    setCancelId(value);
    setCancelModal(true);
  };

  const handleCloseModal = () => {
    handleOpenModal(false);
  };

  const dispatch = useDispatch();
  // const isAuth = useSelector(selectIsAuth);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    dispatch(fetchInfoOrder());
  }, [dispatch]);

  const [expandedItems, setExpandedItems] = useState([]);

  const toggleExpand = index => {
    setExpandedItems(prevExpanded => {
      if (prevExpanded.includes(index)) {
        return prevExpanded.filter(itemIndex => itemIndex !== index);
      } else {
        return [...prevExpanded, index];
      }
    });
  };

  return (
    <section>
      <ul className="completed-services__items">
        {order.items.length !== 0 ? (
          <>
            {order.items
              .slice()
              .reverse()
              .filter(item => {
                if (item.isCanceled || item.state) {
                  return false;
                }
                // isPaid - оплачен ли заказ (true/false)
                if (
                  !item.isPaid &&
                  item.payment_type === "Безналичный расчет"
                ) {
                  return false;
                }
                if (item.payment_type === "Наличные") {
                  return true;
                }
                return true;
              })
              .map((item, index) => {
                const isExpanded = expandedItems.includes(index);
                const date = new Date(item.date);
                const day = date.getDate();
                const month = months[date.getMonth()];
                const hours = date.getHours();
                const minutes = date.getMinutes().toString().padStart(2, "0");

                // if (item.isCanceled || item.state || item.isPaid) {
                //   return null;
                // }

                return (
                  <li
                    className={`completed-services__item ${
                      isExpanded ? "expanded" : ""
                    }`}
                    key={index}>
                    <div className="completed-services__item-header">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          onClick={() => toggleExpand(index)}
                          className="completed-services__arrow">
                          {!isExpanded ? (
                            <img src={arrowImage} width={18} alt="" />
                          ) : (
                            <img
                              src={arrowImage}
                              width={18}
                              alt=""
                              style={{ transform: "rotate(180deg)" }}
                            />
                          )}
                        </span>
                        <div
                          className="completed-services__text"
                          style={{ display: "flex", marginLeft: "8px" }}>
                          {item.group}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="completed-services__text"
                          style={{ marginRight: "16px" }}>
                          Заказ № {item.orderNumber}
                        </div>
                        <div className="completed-services__text">{`${day} ${month} ${hours}:${minutes}`}</div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          onClick={() => handleOpenModal(true, item)}
                          style={{ marginRight: "12px" }}
                          className="completed-services__cancel-button">
                          Изменить
                        </div>
                        <div
                          onClick={() =>
                            handleCancelOrder({
                              id: item._id,
                              orderNumber: item.orderNumber,
                            })
                          }
                          className="completed-services__cancel-button">
                          Отменить
                        </div>
                      </div>
                    </div>
                    {isExpanded && (
                      <span className="completed-services__item-span">
                        <div>
                          <div className="completed-services__text">
                            Тип уборки: <span>{item.group}</span>
                          </div>
                          <div className="completed-services__text">
                            Тип: <span>{item.type}</span>
                          </div>
                          {item.whatPurpose ? (
                            <div className="completed-services__text">
                              Что убираем: <span>{item.whatPurpose}</span>
                            </div>
                          ) : null}
                          <div className="completed-services__text">
                            Количество комнат и ванных:{" "}
                            <span>
                              {item.room_count}, {item.bathroom_count}
                            </span>
                          </div>
                          {item.squareMeters ? (
                            <div className="completed-services__text">
                              Площадь: <span>{item.squareMeters} м²</span>
                            </div>
                          ) : null}
                          <div className="completed-services__text">
                            Сумма заказа: <span>{item.price}</span>
                          </div>
                          <div className="completed-services__text">
                            Списано бонусов: <span>{item.bonusAmount}</span>
                          </div>
                          <div className="completed-services__text">
                            Итого сумма заказа:{" "}
                            <span>{item.price - item.bonusAmount}</span>
                          </div>
                        </div>
                        <div>
                          <div className="completed-services__text">
                            Адрес: <span>{item.address}</span>
                          </div>
                          {item.numberOfFloors ? (
                            <div className="completed-services__text">
                              Количество этажей:{" "}
                              <span>{item.numberOfFloors}</span>
                            </div>
                          ) : null}
                          {"elevator" in item ? (
                            <div className="completed-services__text">
                              Лифт: <span>{item.elevator ? "Да" : "Нет"}</span>
                            </div>
                          ) : null}
                          <div className="completed-services__text">
                            Домофон: <span>{item.intercom}</span>
                          </div>

                          <div className="completed-services__text">
                            Комментарий: <span>{item.comment}</span>
                          </div>
                          <div className="completed-services__text">
                            Тип оплаты: <span>{item.payment_type}</span>
                          </div>
                        </div>
                        <div>
                          Дополнительный сервис
                          <div className="completed-services__text">
                            {item.additional_services.map((services, index) => (
                              <div key={index}>
                                <div className="completed-services__text">
                                  Название: <span>{services.name}</span>
                                </div>
                                <div className="completed-services__text">
                                  Количество: <span>{services.count}</span>
                                </div>
                                <div className="completed-services__text">
                                  Цена: <span>{services.price} ₽</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </span>
                    )}
                  </li>
                );
              })}
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              color: "#b2b2b2",
              padding: "32px 0",
            }}>
            У вас нет заказов
          </div>
        )}
      </ul>
      {modal ? (
        <ChangeOrder
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          data={dataChange}
        />
      ) : null}
      {cancelModal ? (
        <CancelOrder
          handleCloseModal={() => setCancelModal(false)}
          orderId={cancelId.id}
          orderNumber={cancelId.orderNumber}
        />
      ) : null}
    </section>
  );
});

export default MyOrders;
