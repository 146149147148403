import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import MultipleCalendar from "./MultipleCalendar";
import SingleCalendar from "./SingleCalendar";
import { useDispatch, useSelector } from "react-redux";
import { changeTimes, clearDates } from "../../../../redux/slices/calculator";
import TimeInputs from "./TimeInputs";
import { memo } from "react";
import { HiOutlineCursorClick } from "react-icons/hi";

const nameWeekDays = [
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
  "Воскресенье",
];

const CalculatorCalendar = memo(() => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [checked, setChecked] = useState(false);
  const { dates } = useSelector(({ calculator }) => calculator);
  const dispatch = useDispatch();

  const handleChangeSwitch = event => {
    setChecked(event.target.checked);
    dispatch(clearDates());
  };

  const handleChangeTime = (hours, minutes, weekDayNumber) => {
    dispatch(changeTimes({ hours, minutes, weekDayNumber }));
  };

  const [open, setOpen] = useState(false);

  const selectedDate = dates && dates.length > 0 ? dates[0] : null;

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  return (
    <div className="additional-services__calendar-wrapper">
      <div
        className={
          open
            ? `additional-services__title additional-services__title__mobile`
            : `additional-services__title__mobile-open`
        }
        onClick={() => setOpen(!open)}>
        Время уборки
        <span className="additional-services__title__mobile-icon">
          <HiOutlineCursorClick />
        </span>
      </div>
      <span className={open ? `` : `additional-services__mobile`}>
        <div className="additional-services__title">
          Выберите день и время уборки
        </div>
        {/* <div className="additional-services__switch">
          <div className="additional-services__title">Цикличное расписание</div>
          <Switch checked={checked} onChange={handleChangeSwitch} {...label} />
        </div> */}
        <div className="additional-services__time">
          {checked ? <MultipleCalendar /> : <SingleCalendar />}
          <div className="additional-services__inputs">
            {selectedDate ? (
              <div>
                <div>
                  Дата уборки: {selectedDate.day}{" "}
                  {months[selectedDate.month - 1]} {selectedDate.year}
                </div>
                <TimeInputs date={selectedDate} callBack={handleChangeTime} />
              </div>
            ) : (
              <div>Выберите дату</div>
            )}
          </div>
        </div>
      </span>
    </div>
  );
});

export default CalculatorCalendar;
