import React, { useState } from "react";
import { memo } from "react";
import "./TimeInputs.scss";

const TimeInputs = memo(({ date, callBack, weekNumber }) => {
  const [selectedInterval, setSelectedInterval] = useState(null);

  if (!date || !date.year || !date.month || !date.day) {
    return null;
  }

  const generateTimeIntervals = () => {
    const now = new Date();

    const selectedDate = new Date(date.year, date.month - 1, date.day);

    const isToday = now.toDateString() === selectedDate.toDateString();

    const currentHour = now.getHours();

    const allIntervals = [
      { fromHour: 8, toHour: 10 },
      { fromHour: 10, toHour: 12 },
      { fromHour: 12, toHour: 14 },
      { fromHour: 14, toHour: 16 },
      { fromHour: 16, toHour: 18 },
      { fromHour: 18, toHour: 20 },
      { fromHour: 20, toHour: 22 },
    ];

    return allIntervals.map(interval => {
      const fromTime = new Date(selectedDate);
      fromTime.setHours(interval.fromHour, 0, 0, 0);
      const toTime = new Date(selectedDate);
      toTime.setHours(interval.toHour, 0, 0, 0);

      let isAvailable = true;
      if (isToday) {
        isAvailable = interval.fromHour >= currentHour;
      }

      return {
        from: fromTime,
        to: toTime,
        isAvailable: isAvailable,
      };
    });
  };

  const handleSelectTime = interval => {
    if (!interval.isAvailable) {
      return;
    }
    setSelectedInterval(interval);
    if (typeof weekNumber !== "undefined") {
      callBack(
        interval.from.getHours(),
        interval.from.getMinutes(),
        weekNumber
      );
    } else {
      callBack(interval.from.getHours(), interval.from.getMinutes());
    }
  };

  return (
    <div className="time-inputs">
      <ul className="time-intervals-list">
        {generateTimeIntervals().map((interval, index) => (
          <li key={index}>
            <button
              onClick={() => handleSelectTime(interval)}
              className={`time-interval-button ${
                interval.isAvailable ? "" : "disabled"
              }`}
              disabled={!interval.isAvailable}>
              {interval.from.getHours().toString().padStart(2, "0")}:
              {interval.from.getMinutes().toString().padStart(2, "0")} -{" "}
              {interval.to.getHours().toString().padStart(2, "0")}:
              {interval.to.getMinutes().toString().padStart(2, "0")}
            </button>
          </li>
        ))}
      </ul>
      {selectedInterval && (
        <div className="selected-time">
          Выбранное время:{" "}
          {selectedInterval.from.getHours().toString().padStart(2, "0")}:
          {selectedInterval.from.getMinutes().toString().padStart(2, "0")} -{" "}
          {selectedInterval.to.getHours().toString().padStart(2, "0")}:
          {selectedInterval.to.getMinutes().toString().padStart(2, "0")}
        </div>
      )}
    </div>
  );
});

export default TimeInputs;
