import "./Sales.scss";
import Office from "../../../../assets/office.jpg";
import Studi from "../../../../assets/uborka02.jpg";
import Uborka from "../../../../assets/uborka.jpg";
// import { motion } from "framer-motion";
// import { UpAnimation, OpacityAnimation } from "../../../../animations";
import { useRef } from "react";

const Sales = () => {
  const promoRef = useRef(null);
  const copyToClipboard = () => {
    const promoText = "offici";
    navigator.clipboard.writeText(promoText);
    promoRef.current.innerText = "Скопировано!";

    setTimeout(() => {
      promoRef.current.innerText = "offici";
    }, 3000);
  };

  const promoRef2 = useRef(null);
  const copyToClipboard2 = () => {
    const promoText = "First";
    navigator.clipboard.writeText(promoText);
    promoRef2.current.innerText = "Скопировано!";

    setTimeout(() => {
      promoRef2.current.innerText = "First";
    }, 3000);
  };

  const promoRef3 = useRef(null);
  const copyToClipboard3 = () => {
    const promoText = "Ctudiia";
    navigator.clipboard.writeText(promoText);
    promoRef3.current.innerText = "Скопировано!";

    setTimeout(() => {
      promoRef3.current.innerText = "Ctudiia";
    }, 3000);
  };

  return (
    <div className="benefits__wrapper feedback__wrapper">
      <div className="landing__container">
        <div className="benefits__title">Наши скидки</div>
        <ul className="sales__items">
          <li>
            <img src={Office} alt="" />
            <div className="text-container">
              {/* <div>5% на первую уборку офиса</div> */}
              <div>offici на первую уборку офиса</div>
              {/* <div
                className="text-container-sale"
                ref={promoRef}
                onClick={copyToClipboard}
              >
                offici
              </div> */}
            </div>
          </li>
          <li>
            <img src={Studi} alt="" />
            <div className="text-container">
              {/* <div>8% на уборку квартиры</div> */}
              <div>First на уборку квартиры</div>
              {/* <div
                className="text-container-sale"
                ref={promoRef2}
                onClick={copyToClipboard2}
              >
                First
              </div> */}
            </div>
          </li>
          <li>
            <img src={Uborka} alt="" />
            <div className="text-container">
              {/* <div>12 % на уборку студии</div> */}
              <div>Ctudiia на уборку студии</div>
              {/* <div
                className="text-container-sale"
                ref={promoRef3}
                onClick={copyToClipboard3}
              >
                Ctudiia
              </div> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sales;
