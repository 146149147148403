import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";
import { mainPricesMoscow, mainPricesSaintPeterburg } from "../../data";

const typeHome = ["Квартира", "Нежилое помещение", "Дом"];

// Добавление заказа в БД
export const fetchOrderCalculator = createAsyncThunk(
  "order/fetchOrderCalculator",
  async (values, thunkAPI) => {
    const { calculator, user } = thunkAPI.getState();

    const mainCleaning = calculator.items.find(item => item.id === 21);
    const additional_services = calculator.items.filter(item => item.id !== 21);
    const address = `${values?.region}, ${values?.address}, ${values?.flatNumber}`;
    const sendData = {
      group: typeHome[mainCleaning.roomType],
      room_count: mainCleaning.rooms + 1,
      type: mainCleaning.name,
      bathroom_count: mainCleaning.bathroom + 1,
      additional_services,
      address,
      intercom: values.intercom,
      comment: values.comment,
      payment_type: values.payment_type,
      userBalance: values.userBalance,
      bonusAmount: values.bonusAmount,
      price: calculator.totalPriceWithDiscount,
      totalPrice: values.endPrice,
    };
    if (mainCleaning.roomType === 1) {
      sendData.squareMeters = mainCleaning.square;
      sendData.whatPurpose = mainCleaning.whatPurpose;
      sendData.numberOfFloors = mainCleaning.numberOfFloors;
      sendData.elevator = mainCleaning.elevator;
    }
    if (mainCleaning.roomType === 2) {
      sendData.squareMeters = mainCleaning.square;
    }
    if (calculator.discount && calculator.discount._id) {
      sendData.promo = calculator.discount._id;
    }
    if (calculator.dates.length === 1) {
      sendData.date = calculator.dates[0].timestamp;
      sendData.dates = null;
    } else {
      sendData.date = null;
      sendData.dates = calculator.dates.map(item => item.timestamp);
    }
    let response;

    if (user.user && user.user.items && user.user.items._id) {
      response = await axios.post("/api/order", sendData);
    } else {
      sendData.phoneNumber = values.phoneNumber;
      response = await axios.post("/api/order/nouser", sendData);
    }
    if (values.payment_type === "Безналичный расчет") {
      window.location.assign(response.data.url);
    }
    return response.data;
  }
);

export const fetchDiscount = createAsyncThunk("order/discount", async name => {
  const response = await axios.get(`/api/promo/${name}`);
  if (response.data.length > 0) {
    return response.data[0];
  } else {
    alert("Invalid promo code. Please try again.");
    return null;
  }
});

const today = new Date();

const initDate = {
  hours: today.getHours(),
  minutes: today.getMinutes(),
  year: today.getFullYear(),
  month: today.getMonth() + 1,
  day: today.getDate(),
  timestamp: today.getTime(),
  weekDay: today.getDay(),
};

const initialStateForSaintPeterburg = {
  orderData: null,
  totalPrice: 2620,
  discount: null,
  pickup: "Откуда забрать",
  clarify: "Уточнить у Вас",
  totalPriceWithDiscount: 2620,
  dates: [initDate],
  items: [
    {
      id: 21,
      roomType: 0,
      typeId: 0,
      rooms: 0,
      bathroom: 0,
      square: 0,
      name: "Поддерживающая",
      price: 2620,
      count: 1,
    },
  ],
};

const initialState = {
  orderData: null,
  totalPrice: 2620,
  discount: null,
  pickup: "Откуда забрать",
  clarify: "Уточнить у Вас",
  totalPriceWithDiscount: 2620,
  dates: [initDate],
  items: [
    {
      id: 21,
      roomType: 0,
      typeId: 0,
      rooms: 0,
      bathroom: 0,
      square: 0,
      name: "Поддерживающая",
      price: 2620,
      count: 1,
      time: "2 часа",
    },
  ],
};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  extraReducers: {
    [fetchOrderCalculator.pending]: state => {
      state.status = "loading";
    },
    [fetchOrderCalculator.fulfilled]: state => {
      state.status = "loaded";
    },
    [fetchOrderCalculator.rejected]: state => {
      state.status = "error";
    },
    [fetchDiscount.pending]: state => {
      state.status = "loading";
      state.discount = null;
    },
    [fetchDiscount.fulfilled]: (state, action) => {
      state.status = "loaded";
      state.discount = action.payload;
      calculateTotalPrice(state);
    },
    [fetchDiscount.rejected]: state => {
      state.status = "error";
      state.discount = null;
      calculateTotalPrice(state);
    },
  },
  reducers: {
    addProduct(state, action) {
      if (state.items.some(obj => obj.id === action.payload.id)) {
        state.items = state.items.map(obj => {
          if (obj.id === action.payload.id) {
            if (obj.id === 6) {
              obj = action.payload;
            } else {
              obj.count++;
            }
          }
          return obj;
        });
      } else {
        state.items.push(action.payload);
      }
      calculateTotalPrice(state);
    },
    minusItem(state, action) {
      const findItem = state.items.find(item => item.id === action.payload);

      if (findItem) {
        if (findItem.count - 1 === 0) {
          state.items = state.items.filter(item => item.id !== action.payload);
        } else {
          findItem.count--;
        }
        calculateTotalPrice(state);
      }
    },

    updateMainCleaning(state, action) {
      const findItem = state.items.find(item => item.id === 21);
      const data = action.payload;
      let mainPrices;
      if (data.city === 0) {
        mainPrices = mainPricesMoscow;
      } else {
        mainPrices = mainPricesSaintPeterburg;
      }
      let price = 0;
      switch (data.roomType) {
        case 0:
          // квартиры
          const pricesFlats = mainPrices.flats.find(
            item => item.typeId === data.typeId
          );
          price = pricesFlats.prices[data.rooms];
          const time = pricesFlats.time[data.rooms];
          price = price + data.bathroom * pricesFlats.priceBathrooms;
          findItem.price = price;
          findItem.roomType = data.roomType;
          findItem.name = data.name;
          findItem.bathroom = data.bathroom;
          findItem.rooms = data.rooms;
          findItem.time = time;
          break;
        case 1:
          // нежилое
          const pricesNonLiving = mainPrices.nonLiving.find(
            item => item.typeId === data.typeId
          );
          price = pricesNonLiving.pricesForSquare * data.square;
          findItem.roomType = data.roomType;
          findItem.name = data.name;
          findItem.square = data.square;
          findItem.price = price;
          findItem.whatPurpose = data.whatPurpose;
          findItem.numberOfFloors = data.numberOfFloors;
          findItem.elevator = data.elevator;
          break;
        case 2:
          //дом
          findItem.roomType = data.roomType;
          findItem.name = data.name;
          const pricesHouses = mainPrices.houses.find(
            item => item.typeId === data.typeId
          );
          price = pricesHouses.pricesForSquare * data.square;
          findItem.square = data.square;
          findItem.price = price;
          break;
      }
      calculateTotalPrice(state);
    },

    removeProduct(state, action) {
      state.items = state.items.filter(item => item.id !== action.payload.id);
      calculateTotalPrice(state);
    },
    clearProducts(state, action) {
      if (action.payload === 0) {
        return initialState;
      } else {
        return initialStateForSaintPeterburg;
      }
    },
    changeSingleDate(state, action) {
      const oldDate = state.dates[0];
      const hours = oldDate?.hours || 0; // Используем 0, если oldDate или hours не определены
      const minutes = oldDate?.minutes || 0; // Используем 0, если oldDate или minutes не определены

      const newDate = new Date(
        action.payload.year,
        action.payload.month - 1,
        action.payload.day,
        hours,
        minutes
      );

      const newStateDate = {
        hours: newDate.getHours(),
        minutes: newDate.getMinutes(),
        year: newDate.getFullYear(),
        month: newDate.getMonth() + 1,
        day: newDate.getDate(),
        timestamp: newDate.getTime(),
        weekDay: newDate.getDay(),
      };

      state.dates[0] = newStateDate;
    },

    changeMultipleDate(state, action) {
      const newStateDates = [];
      action.payload.map(item => {
        const newDate = new Date(
          item.year,
          item.month - 1,
          item.day,
          item.hours,
          item.minutes
        );
        newStateDates.push({
          hours: newDate.getHours(),
          minutes: newDate.getMinutes(),
          year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate(),
          timestamp: newDate.getTime(),
          weekDay: newDate.getDay(),
        });
      });
      newStateDates.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });
      state.dates = newStateDates;
    },
    changeTimes(state, action) {
      const { weekDayNumber, hours, minutes } = action.payload;

      if (typeof weekDayNumber === "undefined") {
        // Одиночное расписание
        if (state.dates && state.dates.length > 0) {
          const item = state.dates[0];
          const newDate = new Date(
            item.year,
            item.month - 1,
            item.day,
            hours,
            minutes
          );
          const newStateDate = {
            hours: newDate.getHours(),
            minutes: newDate.getMinutes(),
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            day: newDate.getDate(),
            timestamp: newDate.getTime(),
            weekDay: newDate.getDay(),
          };
          state.dates[0] = newStateDate;
        }
      } else {
        // Цикличное расписание
        let searchNumber;
        if (weekDayNumber == 6) {
          searchNumber = 0;
        } else {
          searchNumber = weekDayNumber + 1;
        }
        state.dates = state.dates
          .map(item => {
            if (item.weekDay === searchNumber) {
              const newDate = new Date(
                item.year,
                item.month - 1,
                item.day,
                hours,
                minutes
              );
              const newStateDate = {
                hours: newDate.getHours(),
                minutes: newDate.getMinutes(),
                year: newDate.getFullYear(),
                month: newDate.getMonth() + 1,
                day: newDate.getDate(),
                timestamp: newDate.getTime(),
                weekDay: newDate.getDay(),
              };
              return newStateDate;
            } else {
              return item;
            }
          })
          .sort(function (a, b) {
            return a.timestamp - b.timestamp;
          });
      }
    },

    clearDates(state, action) {
      state.dates = [state.dates[0]];
    },
    changeOrderData(state, action) {
      console.log(action.payload);
      state.orderData = action.payload.orderData;
    },
  },
});

const calculateTotalPrice = state => {
  state.totalPrice = state.items.reduce((sum, obj) => {
    return sum + obj.price * obj.count;
  }, 0);
  if (state.discount) {
    state.totalPriceWithDiscount =
      state.totalPrice - state.totalPrice * state.discount.discount;
  } else {
    state.totalPriceWithDiscount = state.totalPrice;
  }
};

export const {
  changeSingleDate,
  addProduct,
  removeProduct,
  clearProducts,
  minusItem,
  updateMainCleaning,
  changeTimes,
  changeMultipleDate,
  clearDates,
  changeOrderData,
} = calculatorSlice.actions;

export const calculatorReducer = calculatorSlice.reducer;
